import { RumInitConfiguration } from '@datadog/browser-rum';

const isProduction = !!process.env.PRODUCTION;
const DD_OPTIONS: RumInitConfiguration = {
  applicationId: 'e2c41feb-24ae-4210-9c61-55d8e628d2dc',
  clientToken: 'pub383aea5bcd45f922e7d97f10ce835b56',
  site: 'datadoghq.com',
  service: 'ecs-frontend-sites',
  version: 'public:www:1.0.0',
  env: isProduction ? 'prod' : 'staging',
  sessionSampleRate: 50,
  sessionReplaySampleRate: 100,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  trackFrustrations: true,
  trackSessionAcrossSubdomains: true,
  defaultPrivacyLevel: 'mask-user-input'
};
export default DD_OPTIONS;
